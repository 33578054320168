import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FILTERS } from "@src/activity/activity-filter/filters/available-filters";
import { Filter } from "@src/activity/activity-filter/models";
import { BehaviorSubject, map } from "rxjs";

export interface Customer {
  availableFilters: number[];
}

const DEFAULT: Customer = { availableFilters: [] };

/* 
  Service that handles fetching customer related configurations or other data.
*/
@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private customerInfo$ = new BehaviorSubject<Customer>(DEFAULT);

  availableFilters$ = this.customerInfo$.pipe(
    map((customer) => this.mapFilters(customer.availableFilters))
  );

  constructor(private http: HttpClient) {
    this.http
      .get<Customer>("/api/customer")
      .subscribe((customerInfo) => this.customerInfo$.next(customerInfo));
  }

  private mapFilters(filters: number[]): Filter[] {
    return filters
      .filter((filterId) => {
        // TODO: If no match is found, log a DEV error
        if (!FILTERS[filterId]) {
          console.error(`No local definition found for filter id: ${filterId}`);
          return false;
        }

        return true;
      })
      .map((filterDef) => FILTERS[filterDef])
      .sort((a, b) => Number(b.required) - Number(a.required));
  }
}
