import {
  Component,
  input,
  OnInit,
  DestroyRef,
  viewChildren,
  viewChild,
  TemplateRef,
} from "@angular/core";
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { format } from "date-fns";
import {
  DateFnsAdapter,
  MAT_DATE_FNS_FORMATS,
  provideDateFnsAdapter,
} from "@angular/material-date-fns-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { nb } from "date-fns/locale";
import {
  SharedDatePickerComponent,
  TimePickerChangeEvent,
  TimePickerComponent,
} from "@shared/components";
import { DateAndTimeFilter, Filter } from "../../models";
import { FilterBaseHandler, FilterBaseComponent, FilterBase, PillTemplateContext } from "../";

interface DateForm {
  start: FormControl<Date | null>;
  end: FormControl<Date | null>;
}

@Component({
  selector: "ath-date-filter",
  templateUrl: "./date-filter.component.html",
  styleUrls: ["./date-filter.component.scss"],
  standalone: true,
  imports: [
    SharedDatePickerComponent,
    ReactiveFormsModule,
    FilterBaseComponent,
    TimePickerComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: nb },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    provideDateFnsAdapter(),
  ],
})
export class DateFilterComponent extends FilterBase implements OnInit {
  filter = input.required<Filter>();

  private filterHandler: FilterBaseHandler<DateAndTimeFilter>;
  private startTime: TimePickerChangeEvent | undefined;
  private endTime: TimePickerChangeEvent | undefined;
  private timePickers = viewChildren(TimePickerComponent);
  private pillTemplate = viewChild.required<TemplateRef<unknown>>("pillTemplate");

  dateForm = this.fb.group<DateForm>({
    start: this.fb.control<Date | null>(this.defaultStartDate, [Validators.required]),
    end: this.fb.control<Date | null>(this.defaultEndDate, [Validators.required]),
  });

  get defaultStartDate(): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  get defaultEndDate(): Date {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
  }

  constructor(
    private fb: FormBuilder,
    private destroyRef: DestroyRef
  ) {
    super();

    this.filterHandler = new FilterBaseHandler<DateAndTimeFilter>(
      () => this.dateForm.valid,
      () => ({
        key: "Interval",
        values: [this.formatInterval()],
      }),
      () => this.createTemplateContext()
    );

    const clearSub = this.filterHandler.filterCleared$.subscribe(() => {
      this.timePickers().forEach((timePicker) => timePicker.reset());

      // Instead of running manual changedetection after resetting the form
      this.dateForm = this.fb.group<DateForm>({
        start: this.fb.control<Date | null>(this.defaultStartDate, [Validators.required]),
        end: this.fb.control<Date | null>(this.defaultEndDate, [Validators.required]),
      });
    });

    this.destroyRef.onDestroy(() => {
      clearSub.unsubscribe();
    });
  }

  private formatInterval(): string {
    const start = this.dateForm.controls.start.value!;
    const end = this.dateForm.controls.end.value!;
    return `${format(start, "yyyy-MM-dd'T'HH:mm:ss'Z")}/${format(end, "yyyy-MM-dd'T'HH:mm:ss'Z")}`;
  }

  private createTemplateContext(): PillTemplateContext | undefined {
    const context = this.getContext();

    return context
      ? {
          template: this.pillTemplate(),
          context,
        }
      : undefined;
  }

  private getContext():
    | { details: { start: { date: string; time: string }; end: { date: string; time: string } } }
    | undefined {
    const start = this.dateForm.controls.start.value;
    const end = this.dateForm.controls.end.value;

    if (!start || !end) {
      return undefined;
    }

    return {
      details: {
        start: {
          date: format(start, "dd LLL yyyy"),
          time: format(start, "HH:mm"),
        },
        end: {
          date: format(end, "dd LLL yyyy"),
          time: format(end, "HH:mm"),
        },
      },
    };
  }

  ngOnInit(): void {
    this.filterHandler.initialize(this.dateForm.getRawValue(), this.filter());
  }

  onStartDateChange(date: Date | null): void {
    if (this.startTime && date) {
      date.setHours(this.startTime.hour);
      date.setMinutes(this.startTime.minute);
    }

    this.updateExternalState({ start: date });
  }

  onEndDateChange(date: Date | null): void {
    if (this.endTime && date) {
      date.setHours(this.endTime.hour);
      date.setMinutes(this.endTime.minute);
    }

    this.updateExternalState({ end: date });
  }

  onTimeChange(time: TimePickerChangeEvent, type: "start" | "end"): void {
    if (!this.dateForm.controls[type].value) {
      return;
    }

    if (type === "start") {
      this.startTime = time;
    } else {
      this.endTime = time;
    }

    const tempDate = new Date(this.dateForm.controls[type].value);
    tempDate.setHours(time.hour);
    tempDate.setMinutes(time.minute);

    this.dateForm.controls[type].setValue(tempDate);
    this.updateExternalState({ [type]: tempDate });
  }

  private updateExternalState(currState: Partial<DateAndTimeFilter>): void {
    this.filterHandler.updateState(currState);
  }
}
