import { computed, DestroyRef, Injectable, signal } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, InteractionStatus } from "@azure/msal-browser";
import { filter, Observable } from "rxjs";
import { environment } from "@src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  isAuthenticated = signal(false);

  currentAccount = computed<AccountInfo>(() => {
    const authStatus = this.isAuthenticated();
    const currAcc = this.msalService.instance.getActiveAccount();

    if (!authStatus || !currAcc) {
      this.login();
      throw new Error("Authentication failed. Redirecting to login.");
    }

    return currAcc;
  });

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private destroyRef: DestroyRef
  ) {
    const redirectSub = this.msalService.handleRedirectObservable().subscribe();

    const interactionSub = this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.destroyRef.onDestroy(() => {
      redirectSub.unsubscribe();
      interactionSub.unsubscribe();
    });
  }

  private checkAndSetActiveAccount(): void {
    // TODO: Decide on how to handle multiple accounts.
    // When is this relevant? Will there only be externalTenant accounts here?
    if (
      !this.msalService.instance.getActiveAccount() &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }

    if (this.msalService.instance.getActiveAccount()) {
      this.isAuthenticated.set(true);
    } else {
      this.isAuthenticated.set(false);
    }
  }

  handleRedirectObservable(): Observable<AuthenticationResult> {
    return this.msalService.handleRedirectObservable();
  }

  logout(): void {
    this.msalService.logoutRedirect();
  }

  login(): void {
    this.msalService.loginRedirect({
      redirectStartPage: "/activity",
      scopes: [...environment.externalIdConfig.scopes],
    });
  }
}
