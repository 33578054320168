import { ChangeDetectionStrategy, Component, inject, input, signal } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { FiltersService } from "../../services";
import { Filter } from "@src/activity/activity-filter/models";

@Component({
  selector: "ath-filter-base",
  standalone: true,
  imports: [MatExpansionModule, MatIconModule, MatButtonModule],
  templateUrl: "./filter-base.component.html",
  styleUrl: "./filter-base.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBaseComponent {
  private filterService = inject(FiltersService);

  filter = input.required<Filter>();

  isExpanded = signal(true);

  get valid(): boolean {
    return this.filterService.isFilterValid(this.filter().id);
  }

  removeFilter(): void {
    this.filterService.clearFilterValue(this.filter().id);
    this.filterService.toggleFilter(this.filter().id);
  }
}
