import { Injectable } from "@angular/core";
import { LocalStorage } from "./storage-value-models";
import { StorageKeyTypes } from "./storage-keys";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor() {}

  setItem<T extends LocalStorage>(key: StorageKeyTypes, value: T): void {
    try {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } catch (e) {
      console.error("Unable to store information locally", e);
    }
  }

  getItem<T extends LocalStorage>(key: StorageKeyTypes): T | undefined {
    try {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : undefined;
    } catch (e) {
      console.error("Unable to load locally stored information", e);
      return undefined;
    }
  }

  removeItem(key: StorageKeyTypes): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
