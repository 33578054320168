import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  ElementRef,
  input,
  OnInit,
  signal,
  TemplateRef,
  untracked,
  viewChild,
} from "@angular/core";
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  startWith,
} from "rxjs";
import { ENTER } from "@angular/cdk/keycodes";
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { MatChipsModule } from "@angular/material/chips";
import { AsyncPipe } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { LogicalOperator, LogicalOperators, LogicalOperatorToggleComponent } from "../components";
import { Filter, ParticipantsFilter } from "../../models";
import { FilterBaseHandler, FilterBaseComponent, FilterBase, PillTemplateContext } from "../";
import { CustomParam, FilterValueService } from "../../services";
import { Account } from "@shared/models";

@Component({
  selector: "ath-participants-filter",
  standalone: true,
  imports: [
    FilterBaseComponent,
    AsyncPipe,
    MatFormFieldModule,
    ReactiveFormsModule,
    LogicalOperatorToggleComponent,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
  ],
  templateUrl: "./participants-filter.component.html",
  styleUrl: "./participants-filter.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantsFilterComponent extends FilterBase implements OnInit {
  filter = input.required<Filter>();

  private filterHandler: FilterBaseHandler<ParticipantsFilter>;
  private allAccounts$: Observable<Account[]> | undefined;
  private inputField = viewChild.required<ElementRef<HTMLInputElement>>("inputField");
  private pillTemplate = viewChild.required<TemplateRef<unknown>>("pillTemplate");

  readonly keyCodes = [ENTER];
  filteredAccounts$: Observable<Account[]> | undefined;
  selectedAccounts = signal(new Set<Account>([]));
  logicalOperator = signal<LogicalOperator>(LogicalOperators.ANY);
  accountQuery = new FormControl<string>("", { nonNullable: true });

  constructor(
    private filterValueService: FilterValueService<Account>,
    private destroyRef: DestroyRef
  ) {
    super();

    this.filterHandler = new FilterBaseHandler<ParticipantsFilter>(
      () => true,
      () => this.createHttpParams(),
      () => this.createTemplateContext()
    );

    effect(() => {
      const accounts = this.selectedAccounts();
      const logicalOperator = this.logicalOperator();

      untracked(() => {
        this.filterHandler.updateState({
          participants: accounts,
          logicalOperator,
        });
      });
    });

    const clearSub = this.filterHandler.filterCleared$.subscribe(() => {
      this.selectedAccounts.set(new Set<Account>([]));
      this.logicalOperator.set(LogicalOperators.ANY);
      this.clearInput();
    });

    this.destroyRef.onDestroy(() => {
      clearSub.unsubscribe();
    });
  }

  private createHttpParams(): CustomParam | undefined {
    const accountIds = [...this.selectedAccounts().values()].map((account) => account.id);
    const paramKey =
      this.logicalOperator() === LogicalOperators.ANY ? "ParticipantIds" : "RequiredParticipantIds";

    if (accountIds.length) {
      return { key: paramKey, values: accountIds };
    }

    return undefined;
  }

  private createTemplateContext(): PillTemplateContext | undefined {
    const context = this.selectedAccounts().size
      ? { details: `Participants (${this.selectedAccounts().size})` }
      : undefined;

    return context ? { template: this.pillTemplate(), context } : undefined;
  }

  ngOnInit(): void {
    this.filterHandler.initialize(
      { participants: this.selectedAccounts(), logicalOperator: this.logicalOperator() },
      this.filter()
    );

    this.allAccounts$ = this.filterValueService
      .getFilterValues(this.filter().valueEndpoint!)
      .pipe(shareReplay(1));

    this.filteredAccounts$ = combineLatest([
      this.accountQuery.valueChanges.pipe(debounceTime(300), distinctUntilChanged(), startWith("")),
      this.allAccounts$,
    ]).pipe(map(([query, accounts]) => this.filterAccounts(accounts, query)));
  }

  // Very much temporary due to the autocomplete component not seeming to support multi-selection indicators
  private filterAccounts(accounts: Account[], query: string): Account[] {
    const nonSelectedAccounts = accounts.filter((account) => !this.selectedAccounts().has(account));
    return query.length
      ? nonSelectedAccounts.filter((account) =>
          account.value.toLowerCase().includes(query.toLowerCase())
        )
      : nonSelectedAccounts;
  }

  onLogicalOperatorChange(operator: LogicalOperator): void {
    this.logicalOperator.set(operator);
  }

  addAccount(event: MatAutocompleteSelectedEvent): void {
    const account = event.option.value as Account;
    this.selectedAccounts.update((accounts) => {
      accounts.add(account);
      return new Set(accounts);
    });

    this.clearInput();
  }

  private clearInput(): void {
    this.inputField().nativeElement.value = "";
  }

  removeAccount(account: Account): void {
    this.selectedAccounts.update((accounts) => {
      accounts.delete(account);
      return new Set(accounts);
    });
  }
}
