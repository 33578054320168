import { ChangeDetectionStrategy, Component, input, OnInit, output } from "@angular/core";
import { MatButtonToggleChange, MatButtonToggleModule } from "@angular/material/button-toggle";
import { LogicalOperator, LogicalOperators } from "../";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: "ath-logical-operator-toggle",
  standalone: true,
  imports: [MatButtonToggleModule, MatTooltipModule],
  templateUrl: "./logical-operator-toggle.component.html",
  styleUrl: "./logical-operator-toggle.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogicalOperatorToggleComponent implements OnInit {
  logicalOperators = LogicalOperators;

  // Using both compact and vertical will ignore compact
  compact = input(false);
  vertical = input(false);
  valueChange = output<LogicalOperator>();

  ngOnInit(): void {
    this.valueChange.emit(this.logicalOperators.ANY);
  }

  onValueChange(event: MatButtonToggleChange): void {
    this.valueChange.emit(event.value as LogicalOperator);
  }
}
