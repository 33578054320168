import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  input,
  OnInit,
  TemplateRef,
  viewChild,
} from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  ReactiveFormsModule,
  FormArray,
  FormGroup,
  FormControl,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
  NonNullableFormBuilder,
} from "@angular/forms";
import { map } from "rxjs";
import { RecordType, RecordTypeFilter, Filter } from "../../models";
import { FilterBaseHandler, FilterBaseComponent, FilterBase, PillTemplateContext } from "../";
import { CustomParam, FilterValueService } from "../../services";

interface RecordTypeForm {
  types: FormArray<FormGroup<CheckType>>;
}

interface CheckType {
  id: FormControl<string>;
  value: FormControl<string>;
  checked: FormControl<boolean>;
}

const IgnoredTypes: string[] = ["participation", "attachment"];

@Component({
  selector: "ath-record-type-filter",
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, FilterBaseComponent, ReactiveFormsModule],
  templateUrl: "./record-type-filter.component.html",
  styleUrl: "./record-type-filter.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordTypeFilterComponent extends FilterBase implements OnInit {
  filter = input.required<Filter>();

  private filterHandler: FilterBaseHandler<RecordTypeFilter>;
  private pillTemplate = viewChild.required<TemplateRef<unknown>>("pillTemplate");

  form = this.fb.group<RecordTypeForm>({
    types: this.fb.array<FormGroup<CheckType>>([], this.minimumOneSelectedValidator()),
  });

  get isFormValid(): boolean {
    return !this.form.controls.types.length || this.form.valid;
  }

  constructor(
    private filterValueService: FilterValueService<RecordType>,
    private fb: NonNullableFormBuilder,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.filterHandler = new FilterBaseHandler<RecordTypeFilter>(
      () => this.isFormValid,
      () => this.createHttpParam(),
      () => this.createTemplateContext()
    );

    this.form.controls.types.valueChanges.subscribe((values) => {
      this.filterHandler.updateState({
        recordTypes: new Set(values.filter((x) => x.checked) as RecordType[]),
      });
    });

    const clearSub = this.filterHandler.filterCleared$.subscribe((initial) => {
      const test = [...initial.recordTypes.values()];
      this.addFormControls(test);
    });

    this.destroyRef.onDestroy(() => {
      clearSub.unsubscribe();
    });
  }

  private createHttpParam(): CustomParam | undefined {
    const selectedTypeIds: string[] = [];

    this.form.controls.types.value.forEach((type) => {
      if (!type.checked) {
        return;
      }

      const ids = type.id!.split("/");

      selectedTypeIds.push(...ids);
    });

    return selectedTypeIds.length ? { key: "Kinds", values: selectedTypeIds } : undefined;
  }

  private createTemplateContext(): PillTemplateContext | undefined {
    const context = this.getContext();
    return context
      ? {
          template: this.pillTemplate(),
          context,
        }
      : undefined;
  }

  private getContext(): { details: string } | undefined {
    const selectedTypeNames = this.form.controls.types.value
      .filter((type) => type.checked)
      .map((type) => type.value);
    return selectedTypeNames.length ? { details: `${selectedTypeNames.join(" or ")}` } : undefined;
  }

  ngOnInit(): void {
    this.filterValueService
      .getFilterValues(this.filter().valueEndpoint!)
      .pipe(
        map((types) =>
          types
            .filter((x) => !IgnoredTypes.includes(x.id))
            .map((type) => {
              if (type.id === "text") {
                type.id = "text/attachment";
                type.name = "Messages";
              }

              return type;
            })
        )
      )
      .subscribe((types) => {
        this.addFormControls(types);
        this.filterHandler.initialize({ recordTypes: new Set(types) }, this.filter());
      });
  }

  addFormControls(types: RecordType[]): void {
    this.form.controls.types.clear();

    types.forEach((type) =>
      this.form.controls.types.push(
        this.fb.group<CheckType>({
          id: this.fb.control(type.id),
          value: this.fb.control(type.name),
          checked: this.fb.control(true),
        })
      )
    );

    this.cdr.markForCheck();
  }

  private minimumOneSelectedValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasSelection = (control as FormArray<FormGroup<CheckType>>).controls.some(
        (control) => control.value.checked
      );

      return hasSelection ? null : { minimumOneSelected: true };
    };
  }
}
