export const environment = {
  deployed: true,
  externalIdConfig: {
    clientId: "42ea0f2a-ec93-4a34-8500-98f068e9dd02",
    authority: "https://touchrecdevexid2.ciamlogin.com/",
    // TODO configure correct scopes to avoid refetching token https://touchtechnology.atlassian.net/browse/ATH-619
    scopes: ["openid", "profile", "email", "offline_access"],
    uri: "https://dev.touchrec.com/api/*",
  },
};
