<ng-template #pillTemplate let-details="details">
  <span>{{ details }}</span>
</ng-template>

<ath-filter-base [filter]="filter()">
  <ath-dropdown-multiselect
    title="Sources"
    placeholder="All available sources"
    [items]="pristineSources()"
    (selectionUpdated)="updateFilter($event)"
  />
</ath-filter-base>
