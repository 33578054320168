<mat-button-toggle-group
  [class.mat-compact-1]="!vertical() && compact()"
  [vertical]="vertical()"
  name="logicalOperator"
  aria-label="Match all or any of the parameters"
  [value]="logicalOperators.ANY"
  (change)="onValueChange($event)"
  hideSingleSelectionIndicator
>
  <mat-button-toggle
    matTooltip="Recording has to include all the values"
    [value]="logicalOperators.ALL"
    >Match all</mat-button-toggle
  >
  <mat-button-toggle
    matTooltip="Only one of the values has to be present on a record"
    [value]="logicalOperators.ANY"
    >Match any</mat-button-toggle
  >
</mat-button-toggle-group>
