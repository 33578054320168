<ng-template #pillTemplate let-details="details">
  <b>{{ details.start.date }}</b> {{ details.start.time }} - <b>{{ details.end.date }}</b>
  {{ details.end.time }}
</ng-template>

<ath-filter-base [filter]="filter()">
  <form [formGroup]="dateForm">
    <div class="date-picker-container">
      <div class="datetime-pick">
        <ath-date-picker
          label="Start"
          [control]="dateForm.controls.start"
          (dateChange)="onStartDateChange($event)"
          [maxDate]="dateForm.controls.end.value ?? defaultEndDate"
        />
        <ath-time-picker defaultValue="00:00" (timeChange)="onTimeChange($event, 'start')" />
      </div>
      <div class="datetime-pick">
        <ath-date-picker
          label="End"
          [control]="dateForm.controls.end"
          (dateChange)="onEndDateChange($event)"
          [minDate]="dateForm.controls.start.value"
          [maxDate]="defaultEndDate"
        />
        <ath-time-picker defaultValue="23:59" (timeChange)="onTimeChange($event, 'end')" />
      </div>
    </div>
  </form>
</ath-filter-base>
