@let isValid = valid;

<mat-expansion-panel
  [expanded]="isExpanded()"
  (closed)="isExpanded.set(false)"
  (opened)="isExpanded.set(true)"
>
  <mat-expansion-panel-header>
    <mat-panel-title [class.error]="!isValid">
      {{ filter().name }}
      @if (!isValid) {
        <mat-icon class="error-icon" svgIcon="warning" />
      }
    </mat-panel-title>

    @if (!filter().required) {
      <button mat-icon-button (click)="removeFilter()">
        <mat-icon svgIcon="delete" />
      </button>
    }
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <ng-content />
  </ng-template>
</mat-expansion-panel>
