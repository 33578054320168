import { SelectionModel } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { Recording } from "@shared/models";
import { map } from "rxjs";

@Injectable()
export class GridSelectionService {
  private selection = new SelectionModel<Recording>(true, []);

  selectedCount = toSignal(this.selection.changed.pipe(map((x) => x.source.selected.length)));

  get selectedRecordings(): Recording[] {
    return this.selection.selected;
  }

  toggle(recording: Recording): void {
    this.selection.toggle(recording);
  }

  toggleAll(totalRecordingCount: number, recordings: Recording[]): void {
    if (this.isAllSelected(totalRecordingCount)) {
      this.selection.clear();
    } else {
      this.selectBatch(recordings);
    }
  }

  select(recording: Recording): void {
    this.selection.select(recording);
  }

  selectBatch(recordings: Recording[]): void {
    this.selection.setSelection(...recordings);
  }

  clear(): void {
    this.selection.clear();
  }

  isSelected(recording: Recording): boolean {
    return this.selection.isSelected(recording);
  }

  isAllSelected(totalRecordingCount: number): boolean {
    const numSelected = this.selection.selected.length;
    return numSelected === totalRecordingCount && numSelected > 0;
  }
}
