<ng-template #pillTemplate let-details="details">
  <span>{{ details }}</span>
</ng-template>

<ath-filter-base [filter]="filter()">
  <form [formGroup]="form" class="record-type-filter">
    @for (type of form.controls.types.controls; track type.controls.value.value) {
      <mat-checkbox [formControl]="type.controls.checked">
        {{ type.controls.value.value }}
      </mat-checkbox>
    }
  </form>

  @if (!isFormValid) {
    <div class="info-column">
      <span>Please select at least one record type</span>
    </div>
  }
</ath-filter-base>
