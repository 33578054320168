import { ParticipantsFilterComponent } from "./participants-filter/participants-filter.component";
import { DateFilterComponent } from "./date-filter/date-filter.component";
import { UsersFilterComponent } from "./users-filter/users-filter.component";
import { SourceFilterComponent } from "./source-filter/source-filter.component";
import { RecordTypeFilterComponent } from "./record-type-filter/record-type-filter.component";
import { Filter } from "../models";

// Available filters are defined outside the frontend.
// Mocked in mock-backend-interceptor.ts
export const FILTERS: Record<number, Filter> = {
  0: {
    id: 0,
    active: false,
    required: true,
    name: "Date and time",
    component: DateFilterComponent,
  },
  1: {
    id: 1,
    active: true,
    required: false,
    name: "Sources",
    valueEndpoint: "sources",
    component: SourceFilterComponent,
  },
  2: {
    id: 2,
    active: false,
    required: false,
    name: "Users",
    valueEndpoint: "persons",
    component: UsersFilterComponent,
  },
  3: {
    id: 3,
    active: true,
    required: false,
    name: "Participants",
    valueEndpoint: "accounts",
    component: ParticipantsFilterComponent,
  },
  4: {
    id: 4,
    active: false,
    required: true,
    name: "Recording type",
    valueEndpoint: "recording-types",
    component: RecordTypeFilterComponent,
  },
};
