import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { intervalToDuration } from "date-fns";
import { CountedValues, DetailedRecording, Interval, Recording } from "@shared/models";
import { RecordsRequestParams } from "@src/activity/models";
import { RecordingResponse, RecordingsResponse } from "../models";

@Injectable()
export class GridApiService {
  private readonly BASE_URL = "/api/v1";
  private http = inject(HttpClient);

  fetchRecords(queryParams: RecordsRequestParams): Observable<CountedValues<Recording>> {
    const skip = queryParams.pagination.page * queryParams.pagination.pageSize;
    const take = queryParams.pagination.pageSize;

    let params = new HttpParams({
      fromObject: {
        Skip: skip,
        Take: take,
        OrderBy: "Interval",
        Direction: queryParams.sort.direction,
      },
    });

    queryParams.filters?.forEach((filter) => {
      const param = filter.createHttpParam();
      if (param) {
        params = params.appendAll({ [param.key]: param.values });
      }
    });

    return this.http
      .get<CountedValues<RecordingsResponse>>(`${this.BASE_URL}/recordings`, {
        params,
      })
      .pipe(
        map((countedRecordings) => {
          const mappedRecordings = countedRecordings.values.map(this.mapRecording);

          return {
            values: mappedRecordings,
            totalCount: countedRecordings.totalCount,
          };
        })
      );
  }

  private mapRecording = (recording: RecordingsResponse): Recording => {
    const interval = this.mapInterval(recording.interval);

    return {
      ...recording,
      interval: interval,
      duration: this.formatDuration(interval),
    };
  };

  getRecordById(id: string): Observable<DetailedRecording> {
    return this.http
      .get<RecordingResponse>(`${this.BASE_URL}/recordings/${id}`)
      .pipe(map((rec) => this.mapDetailedRecording(rec)));
  }

  private mapDetailedRecording = (recording: RecordingResponse): DetailedRecording => {
    return {
      ...recording,
      interval: this.mapInterval(recording.interval),
    };
  };

  private mapInterval(interval: string): Interval {
    const [startDateString, endDateString] = interval.split("/");
    const start = new Date(startDateString);
    const end = new Date(endDateString);

    return { start, end };
  }

  private formatDuration(interval: Interval): string {
    const { hours, minutes, seconds } = intervalToDuration(interval);

    if (!hours && !minutes && !seconds) {
      return "";
    }

    return `${hours?.toString().padStart(2, "0") ?? "00"}:${
      minutes?.toString().padStart(2, "0") ?? "00"
    }:${seconds?.toString().padStart(2, "0") ?? "00"}`;
  }
}
